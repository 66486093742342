.browserupgrade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
    text-align: center;
}

body {
    visibility: visible;
    opacity: 1;
    margin: 0;
    padding: 0;
}

// hide texts while webfonts are loading
.webfonts-loading {
    h2,h3,h4,h5,h6,p,span,strong,li,a {
        visibility: hidden;
    }
}
// switch font-family once webfonts have loaded
.webfonts-loaded body, .no-js body {
    font-family: 'Cairo', #{$body-font-family};
}

.pagewrapper {
    max-width: $global-width;
    margin: 0 auto;
    padding: rem(0 25);


    @include breakpoint(medium) {
        max-width: 95vw;
        padding: 0;
    }

    @include breakpoint(large) {
        max-width: 90vw;
    }

    @include breakpoint(xlarge) {
        max-width: 80vw;
    }

    @include breakpoint(xxlarge) {
        max-width: $global-width;
        padding: rem(0 150);
    }

}

.pagecontent {
    margin: 0 auto;
    @include breakpoint(large) {
        max-width: rem(1250);
    }
    @include breakpoint(xxlarge) {
        max-width: none;
    }
}

.pagefooter {
    display: flex;
    flex-wrap: wrap;
    padding: rem(30 10 0);
    font-size: rem(15);

    > * {
        flex: 1 0 100%;
        margin-bottom: rem(30);
        text-align: center;

        @include breakpoint(medium) {
            flex: 1 1 33%;
        }
    }

    .left {
        @include breakpoint(medium) {
            text-align: left;
        }
    }

    .center {
        display: none;
        align-self: center;

        @include breakpoint(medium) {
            display: block;
        }
    }

    .right {
        @include breakpoint(medium) {
            text-align: right;
        }
    }

    nav {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li.active a {
            font-weight: $global-weight-bold;
        }
    }

    p {
        font-size: inherit;
    }

    .logo {
        width: em(40);
        font-size: em(26);
        margin: 0 auto;
    }
}

.pagebackground {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f5f1ee linear-gradient(to right, #fffcfc 0%, #f5f1ee 100%);
    background-size: cover;
    background-position: center center;
    opacity: 0.75;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 40vh;
        min-height: rem(400);
        background: linear-gradient(to bottom, rgba(255,255,255,0.9) 33%,rgba(247,242,240,0) 100%);
        z-index: 1;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20vh;
        min-height: rem(300);
        background: linear-gradient(to top, rgba(250,250,250,0.7) 33%,rgba(247,242,240,0) 100%);
        z-index: 1;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

}