h1,h2,h3,h4,h5,h6 {
    margin-top: $paragraph-margin-bottom * 2;

    small {
        display: block;
        text-transform: uppercase;
        color: inherit;
        font-size: rem(14);
        font-weight: $global-weight-bold;
        line-height: 1.4;
        letter-spacing: 0.05em;
    }

    a {
        color: inherit;

        &:hover {
            color: inherit;
        }
    }
}

a {
    transition: color 0.3s ease-in-out;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.color-red {
    color: $primary-color !important;
}

.color-white {
    color: #fff !important;
}