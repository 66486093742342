@charset "UTF-8";

// import normalize.css
@import 'node_modules/modern-css-reset/src/reset';

// import foundation settins and scss
@import "settings";
@import "functions";
@import "node_modules/foundation-sites/scss/foundation";

@include foundation-global-styles;
/*
@if not $global-flexbox {
    @include foundation-grid;
}
@else {
    @include foundation-flex-grid;
    @include foundation-flex-classes;
}
*/
@include foundation-typography;
// @include foundation-forms;
// @include foundation-button;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;

// page elements

@import "forms";
@import "lazyload";
@import "template";
@import "typography";
@import "pageheader";
@import "cards";
@import "buttons";
