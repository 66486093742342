@keyframes moveIn {
    0% {
        top: 250px;
        opacity: 0;
    }
}
@keyframes moveOut {
    100% {
        top: 250px;
        opacity: 0;
    }
}

@mixin cards-2-columns {
    width: 50%;
    max-width: rem(650);

    &:nth-child(even) {
        top: rem(-45);
    }
}

@mixin cards-3-columns {
    width: 33.33333%;
    max-width: rem(650);

    &:nth-child(even) {
        top: 0;
    }
    &:nth-child(3n-1) {
        top: rem(-25);
    }
    &:nth-child(3n) {
        top: rem(-45);
    }
}

.cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    margin: rem(-10 -25 -25);
    box-shadow: rem(0 0 25 0) rgba(0,0,0,0.15);

    @include breakpoint(321) {
        margin-top: rem(-25);
        box-shadow: none;
    }

    @include breakpoint(medium) {
        justify-content: flex-start;
    }

    /*
    &.cards-2-columns {
        .card-wrapper {
            @include breakpoint(medium) {
                @include cards-2-columns;
            }

            @include breakpoint(xxlarge) {
                @include cards-3-columns;
            }
        }
    }

    &.cards-3-columns {
        .card-wrapper {
            @include breakpoint(medium) {
                @include cards-3-columns;
            }
        }
    }
    */

    &::before { // prevent salvattore settings to be visible while loading JS on slow networks
        display: block;
        position: absolute;
        font-size: 1px;
        visibility: hidden;
    }

    @include breakpoint(medium) {
        &.cards-medium {
            .card-wrapper {
                width: 50%;
            }
        }

        &.cards-small {
            .card-wrapper {
                width: 33.333%;
            }
        }
    }

    + .cards {
        margin-top: rem(30);
    }
}

.card-wrapper {
    z-index: 1;
    position: relative;
    display: block;
    width: 100%;
    padding: rem(25);

    top: 0;
    opacity: 1;
    animation-delay: 0.6s;

    @include breakpoint(medium) {
        animation: moveIn 0.8s backwards;

        &.content-at-top, &.content-at-bottom {
            &:before {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            .card {
                position: absolute;
                top: rem(25);
                right: rem(25);
                bottom: rem(25);
                left: rem(25);
            }

            .card-background {
                margin: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
            }
        }
    }

    // move in animation delays
    @for $i from 1 through 12 {
        &.nth-#{$i} {
            animation-delay: calc(0.1s + ($i * 0.15s));
        }
    }

    &.content-above-background {
        .card-content {
            order: 1;
            padding: rem(26 30 80);
            background: linear-gradient(to top, rgba(255,255,255,0) 0, rgba(255,255,255,1) rem(100))
        }

        .card-background {
            margin-bottom: 0;
            margin-top: rem(-110);
        }
    }
}

@include breakpoint(medium) {
    html.navigation-in-progress .card-wrapper {
        animation: moveOut 0.4s forwards;
        animation-delay: 0s;
    }
}

.no-animation-delay .card-wrapper {
    animation-delay: 0s;
}

.card {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    position: relative;
    margin: rem(0);
    overflow: hidden;

    //background-color: $light-gray;
    box-shadow: none;
    transition: box-shadow 0.4s ease-in-out;
    transform: translateZ(0);

    // these are properties for the object-fit fallback [objectfit is currently disabled because we use pthumb to just set square images]
    //background-size: cover;
    //background-position: center center;

    &:hover {
        @include breakpoint(321) {
            box-shadow: rem(0 0 37 0) rgba(0, 0, 0, 0.22);
        }
        .card-background {
            transform: translateZ(0) scale(1.075) rotate(-1deg);
        }
    }

    @include breakpoint(321) {
        border-radius: rem(3);
        box-shadow: rem(0 0 27 0) rgba(0,0,0,0.20);
    }
}

// max height for news cards
.card.news-card {
    @media screen and (min-height: 740px) {
        max-height: 95vh;
    }
}


.card-content {
    width: 100%; // required for IE
    padding: rem(80 30 14);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,1) rem(100));

    header {

        img {
            width: rem(30);
            height: rem(30);
            margin: rem(0 0 10 -2);
        }

        @include breakpoint(medium) {
            display: flex;

            > * {
                flex: 1 1 auto;
            }

            img {
                flex: 0 0 auto;
                width: rem(40);
                height: rem(40);
                margin: rem(3 15 0 -2);
            }
        }

        @include breakpoint(large) {
            img {
                width: rem(50);
                height: rem(50);
            }
        }
    }

    > *:first-child , header > *, div > *:first-child  {
            // remove margin-top from headlines etc in cards at the very top
            margin-top: 0;
    }

    h2 a {
        //color: $body-font-color;
    }

    a {
        position: relative;
        z-index: 3; // position links above the .card-link-overlay
    }
}

.card-background {
    z-index: -1;
    position: relative;
    width: 100%;
    margin-bottom: rem(-110);
    background: #fff;
    transition: opacity 0.3s ease-in-out, transform 1.5s ease-in-out;
    //transform: translateZ(0);
    //will-change: transform;

    img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }

    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.card-link-overlay {
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    text-indent: 200%;
    white-space: nowrap;
    background: transparent;
    cursor: pointer;
}



.card-wrapper.content-at-top {
    .card {
        align-items: flex-start;
        flex-direction: column;
    }

    .card-content {
        padding: rem(27 30 90);
        background: linear-gradient(to top, rgba(255,255,255,0) 0, rgba(255,255,255,1) rem(100));
    }

    .card-background {
        margin-bottom: 0;
        margin-top: rem(-110);
    }

    @include breakpoint(medium) {
        .card-background {
            margin: 0;
        }
    }
}

.card-wrapper.background-red {
    .card-background {
        background: $primary-color;
    }
    * {
        color: #fff;
    }
    a {
        color: #e4e4e4;
    }
}

.card-wrapper.background-mediumgray {
    .card-background {
        background: $medium-gray;
    }
    * {
        color: #fff;
    }
    a {
        color: #da1c1c;
    }
}

.card-wrapper.background-lightgray {
    .card-background {
        background: $light-gray;
    }
}

.card-wrapper.background-darkgray {
    .card-background {
        background: rgb(40,40,40);
    }
    * {
        color: #fff;
    }
    a {
        color: #da1c1c;
    }
}

.card-wrapper.background-no-image {
    .card-content {
        padding-top: rem(27);
        padding-bottom: rem(14);
        background: transparent;
    }
    .card-background {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
    }
}

.card-wrapper.flexible-height {
    &:before {
        content: none;
        display: none;
    }

    .card {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @include breakpoint(321) {
            margin: rem(25);
        }
    }
}
