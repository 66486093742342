.button {
    display: inline-block;
    padding: rem(16 40);
    background: $primary-color;
    color: #fff;
    border: none;
    border-radius: rem(4);
    font-weight: $global-weight-bold;
    transition: background-color 0.25s ease-in-out;
    outline: none;
    max-width: rem(350);
    cursor: pointer;

    &:hover, &:focus {
        background-color: rgb(224, 0, 0);
    }

    &:active {
        background-color: rgb(185, 0, 0);
        transition-duration: 0.15s;
    }

}

#loadCardsButton {
    margin: rem(25 0 0);
}

.button.active {
    background-image: url(/assets/templates/freshpepper-de/img/spinner.gif);
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: rem(-500);
    overflow: hidden;
    max-width: rem(80);
    transition: background-color 0.25s ease-in-out, max-width 0.5s ease-in-out;
}

.button.disabled {
    background: $medium-gray;
}
