.pageheader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    padding: rem(30 15 40);
    max-width: rem(600);

    @include breakpoint(medium) {
        max-width: rem(1250);
        padding: rem(40 30 45);
    }

    @include breakpoint(xxlarge) {
        max-width: none;
    }
}

@keyframes bounceFrames{
    0% {
        transform:  translate(0px,0px)  scaleY(1.00) ;
    }
    9% {
        transform:  translate(0,em(2px))  scaleY(0.94) ;
    }
    24% {
        transform:  translate(0,em(-9px))  scaleY(1.01) ;
    }
    50% {
        transform:  translate(0,em(-24px))  scaleY(1.03) ;
    }
    53% {
        transform:  translate(0,em(-23px))  scaleY(1.03) ;
    }
    70% {
        transform:  translate(0,em(-9px))  scaleY(1.01) ;
    }
    84% {
        transform:  translate(0,0)  scaleY(0.98) ;
    }
    95% {
        transform:  translate(0,0)  scaleY(1.0) ;
    }
    100% {
        transform:  translate(0,0)  scaleY(1.00) ;
    }
}
@keyframes bounceShadowFrames{
    0% {
        transform: scaleX(1.0);
    }
    9% {
        transform: scaleX(1.0);
    }
    50% {
        transform: scaleX(1.7);
    }
    84% {
        transform: scaleX(1.0);
    }
    100% {
        transform: scaleX(1.0);
    }
}

.logo-container {
    width: rem(177);
    margin: 0 auto;
    padding: rem(1 1 1 1);
    flex-shrink: 0;

    @include breakpoint(321px) {
        width: rem(202);
    }

    @include breakpoint(medium) {
        width: rem(227);
        margin: 0;
        padding: rem(1 25 1 1);
    }

    h1 {
        display: block;
        margin: 0;
        padding: 0;
        line-height: 1;
        font-size: inherit;
    }
}

.logo {
    position: relative;
    display: block;
    font-size: rem(17);
    width: em(200);
    height: em(58);
    background: transparent;

    svg {
        position: absolute;
        overflow: visible;
    }

    .pepper {
        top: em(0);
        left: em(0);
        width: em(40.1);
        height: em(46.1);
    }

    .shadow {
        top: em(51);
        left: em(13);
        width: em(14.1);
        height: em(2.1);
    }

    .text {
        top: em(11);
        left: em(51);
        width: em(149.1);
        height: em(39.1);
    }

    &:hover {
        .pepper {
            //transform: translateY(rem(-8)) scale(1.01);
            animation: bounceFrames linear 0.6s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
        }
        .shadow {
            //transform: translateX(-110%) scale(1.8, 1.0);
            animation: bounceShadowFrames linear 0.6s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
        }
    }

}

.pagenav {
    margin-top: rem(-10);
    text-align: right;

    button.open-nav {
        margin: rem(17 -10 0 0);
        padding: rem(15);
        outline: none;
        transition: background-color 0.25s ease-in-out, fill 0.25s ease-in-out;
        border-radius: 50%;

        position:fixed;
        z-index: 9999;
        bottom: rem(12);
        right: rem(20);

        /*
        border: 0 none;
        background-color: $primary-color;
        fill: #fff;
        */

        //&.active, &:hover, &:focus {
            cursor: pointer;
            fill: $primary-color;
            background: #fff;
            box-shadow: rem(0 0 15) rgba(0,0,0,0.2);
            border: rem(5) solid $primary-color;
        //}

        &.active {
            .line-1 {
                transform: rotate(45deg) translate(5px, -5px);
                transform-origin: 0 0;
            }
            .line-2 {
                opacity: 0;
            }
            .line-3 {
                transform: rotate(-45deg) translate(-14px, -3px);
                transform-origin: 0 0;
            }
        }

        @include breakpoint(medium) {
            display: none;
        }
    }

    .hamburger {
        height: rem(24);
        width: rem(24);
        vertical-align: middle;

        .line-1, .line-3 {
            transition: transform 0.3s ease-in;
        }
        .line-2 {
            opacity: 1;
            transition: opacity 0.3s ease-out;
        }
    }

    nav {
        @include breakpoint(small only) {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            visibility: hidden;
            display: flex;
            align-items: center;

            &.active {
                visibility: visible;
                opacity: 1;
                z-index: 9998;
            }

            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(204,0,0,0.9);

            a {
                margin: 0;
                padding: rem(15 0);
                color: #fff;
            }

            ul {
                width: 100%;
                margin: rem(0 25);
                text-align: center;
                border-bottom: 1px solid #fff;
            }

            li {
                display: block;
                font-size: rem(18);
                border-top: 1px solid #fff;

                &.active a, a:active, a:focus {
                    color: #ccc;
                    font-style: italic;
                }
            }
        }
    }

    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0;
    }

    li {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: rem(15);
        text-transform: uppercase;

        &:last-child a {
            margin-right: 0;
            padding-right: 0;
        }

        &.active a {
            color: $anchor-color;
        }
    }

    a {
        display: block;
        padding: rem(10);
        margin: 0 rem(10);
        color: #393734;
        font-weight: $global-weight-bold;
        transition: color 0.3s ease-in-out;

        &:active, &:hover, &:focus {
            color: $anchor-color-hover;
        }
    }
}