label{
  display: inline-block;
  min-width: rem(100);
}
input{
  margin: rem(3 0 15 0);
  border: 1px solid #ccc;
  padding: rem(3 10);
  outline: none;

  &:focus {
    border: 0 0 0 rem(3) $primary-color;
  }
}
input[type=submit]{
  padding: rem(10 30);
  max-width: 100%;
}
